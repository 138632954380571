import axios from "axios";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { register } from "../../services/AuthService";
import { getCustomerCategories } from "../../services/CustomerService";
import { getPOSStates } from "../../services/StateService";
import { getAllCountry } from "../../services/CountryService";
import { AppContext } from "../../utils/AppProvider";
import Popup from "../Popup";

const Register = () => {
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [customerCategory, setCustomerCategory] = useState([]);
  const [isToggle, setisToggle] = useState(false);
  const [confirmationPopup, setconfirmationPopup] = useState(false);
  const [state, setState] = useState({
    name_prefix: "Mr.",
    name: "",
    email: "",
    company_name: "",
    address: "",
    pincode: "",
    city: "",
    country_id: "",
    state_id: "",
    tax_id: "",
    tax_expiry_date: "",
    tax_certificate: "",
    password: "",
    phone: "",
    cc_tax_id: "",
    dba: "",
    mobile: "",
    tob_license_no: "",
    tob_expiry_date: "",
    tob_certificate: "",
  });
  const { setIsAuthenticated } = useContext(AppContext);

  const navigate = useNavigate();
  const [passwordType, setpasswordType] = useState(false);
  let date = new Date();
  date =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate();

  useEffect(() => {
    getCountry();
    getCustomerCategory();
  }, []);

  const getCountry = async () => {
    await getAllCountry()
      .then((response) => {
        setCountry(response.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const getCustomerCategory = async () => {
    await getCustomerCategories()
      .then((response) => {
        setCustomerCategory(response.data.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const getPOSState = async (id) => {
    await getPOSStates(id)
      .then((response) => {
        setStates(response.data.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name == "country_id" && state.state_id != value) {
      getPOSState(value);
    }
    if (name == "phone" || name == "mobile" || name == "manager_number") {
      if (value != null) {
        var x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        value = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      }
    }

    if (name == "tax_certificate" || name == "tob_certificate") {
      setState({ ...state, [name]: e.target.files[0] });
      return;
    }

    setState({ ...state, [name]: value });
  };

  const handleSubmit = async () => {
    if (validateInput()) {
      var phonenumber = state.phone?.replace(/\D/g, "");
      var mobile = state.mobile?.replace(/\D/g, "");
      var manager_number = state.manager_number?.replace(/\D/g, "");
      let formdata = new FormData();
      formdata.append("name_prefix", state.name_prefix);
      formdata.append("name", state.name);
      formdata.append("email", state.email);
      formdata.append("company_name", state.company_name);
      formdata.append("address", state.address);
      formdata.append("pincode", state.pincode);
      formdata.append("city", state.city);
      formdata.append("country_id", state.country_id);
      formdata.append("state_id", state.state_id);
      formdata.append("tax_id", state.tax_id);
      formdata.append("tax_expiry_date", state.tax_expiry_date);
      formdata.append("tax_certificate", state.tax_certificate);
      formdata.append("password", state.password);
      formdata.append("phone", phonenumber);
      formdata.append("cc_tax_id", state.cc_tax_id);
      formdata.append("dba", state.dba);
      formdata.append("mobile", mobile);
      formdata.append("tob_license_no", state.tob_license_no);
      formdata.append("tob_expiry_date", state.tob_expiry_date);
      formdata.append("tob_certificate", state.tob_certificate);
      await register(formdata)
        .then((response) => {
          localStorage.setItem("is_authenticated", 1);
          localStorage.setItem("token", response.data.data.token);
          setIsAuthenticated(1);
          toast("Customer Registered Successfully", { type: "success" });
          // navigate("/");
          setconfirmationPopup(true);
        })
        .catch((error) => {
          if (error.response.status == 422)
            toast(error.response.data.error, { type: "error" });
          else if (error.response.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const validateInput = () => {
    if (state.name == "") {
      toast("Please enter name.", {
        type: "error",
      });
      return false;
    }

    if (state.email == "") {
      toast("Please enter email.", {
        type: "error",
      });
      return false;
    }

    if (state.password == "") {
      toast("Please enter password.", {
        type: "error",
      });
      return false;
    }
    if (state.company_name == "") {
      toast("Please enter company name.", {
        type: "error",
      });
      return false;
    }
    if (state.address == "") {
      toast("Please enter addess.", {
        type: "error",
      });
      return false;
    }
    if (state.city == "") {
      toast("Please enter city.", {
        type: "error",
      });
      return false;
    }
    if (state.country_id == "0") {
      toast("Please select country.", {
        type: "error",
      });
      return false;
    }
    if (state.state_id == "") {
      toast("Please select states.", {
        type: "error",
      });
      return false;
    }

    if (state.pincode == "") {
      toast("Please enter postcode.", {
        type: "error",
      });
      return false;
    }

    if (state.mobile == "") {
      toast("Please enter Primary Contact.", {
        type: "error",
      });
      return false;
    }
    if (state.tob_license_no == "") {
      toast("Please Enter Tobacco ID.", {
        type: "error",
      });
      return false;
    }
    if (state.cc_tax_id == "") {
      toast("Please select customer category.", {
        type: "error",
      });
      return false;
    }

    return true;
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <main id="main" className="site-primary">
      <div className="site-content">
        <div className="homepage-content">
          <div className="shop-content my-account-page">
            <div className="container">
              <nav className="woocommerce-breadcrumb">
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>My account</li>
                </ul>
              </nav>
              <div className="woocommerce">
                <div className="woocommerce-notices-wrapper"></div>
                <div className="site-login">
                  <div className="site-login-container">
                    <div className="site-login-overflow">
                      <ul className="login-page-tab">
                        <li>
                          {" "}
                          <Link to="/login"> Login </Link>{" "}
                        </li>

                        <li>
                          <a href="#" className="active">
                            Register
                          </a>
                        </li>
                      </ul>
                      <div id="customer_login" className="login-form-container">
                        <div className="register-form">
                          <form
                            method="post"
                            className="woocommerce-form woocommerce-form-register register"
                          >
                            {/* <p
                              className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide"
                              style={{ display: "-webkit-inline-box" }}
                            >
                              <div>
                                <label htmlFor="Prefix">
                                  {" "}
                                  Prefix &nbsp;
                                  <span className="required">*</span>
                                </label>
                                <select
                                  name="name_prefix"
                                  value={state.name_prefix}
                                  onChange={(e) => handleInputChange(e)}
                                  className="orderby filterSelect"
                                >
                                  <option value="Mr.">Mr.</option>
                                  <option value="Ms.">Ms.</option>
                                  <option value="Mrs">Mrs.</option>
                                  <option value="Miss.">Miss.</option>
                                </select>
                              </div>
                              <div>
                                <label htmlFor="username">
                                  {" "}
                                  Name &nbsp;<span className="required">*</span>
                                </label>
                                <input
                                  onKeyDown={(e) => _handleKeyDown(e)}
                                  onChange={(e) => handleInputChange(e)}
                                  type="text"
                                  className="woocommerce-Input woocommerce-Input--text input-text"
                                  name="name"
                                  id="username"
                                  autoComplete="username"
                                  value={state.name}
                                />
                              </div>
                            </p> */}

                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="reg_email">
                                Name&nbsp;
                                <span className="required">*</span>
                              </label>
                              <input
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="name"
                                id="username"
                                autoComplete="username"
                                value={state.name}
                              />
                            </p>

                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="reg_email">
                                Email address&nbsp;
                                <span className="required">*</span>
                              </label>
                              <input
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                type="email"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="email"
                                id="reg_email"
                                autoComplete="email"
                                value={state.email}
                              />
                            </p>

                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="reg_password">
                                Password&nbsp;
                                <span className="required">*</span>
                              </label>
                              <span className="password-input">
                                <input
                                  onKeyDown={(e) => _handleKeyDown(e)}
                                  onChange={(e) => handleInputChange(e)}
                                  value={state.password}
                                  type={passwordType ? "text" : "password"}
                                  className="woocommerce-Input woocommerce-Input--text input-text"
                                  name="password"
                                  id="reg_password"
                                  autoComplete="new-password"
                                />
                                <i
                                  className={
                                    "show-password " +
                                    (passwordType
                                      ? "klbth-icon-circle-empty"
                                      : "klbth-icon-circle")
                                  }
                                  title="Show Password"
                                  onClick={() => setpasswordType(!passwordType)}
                                ></i>
                              </span>
                            </p>

                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="company_name">
                                Company Name&nbsp;
                                <span className="required">*</span>{" "}
                              </label>
                              <input
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="company_name"
                                id="company_name"
                                value={state.company_name}
                              />
                            </p>

                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="address">
                                Address&nbsp;<span className="required">*</span>
                              </label>
                              <textarea
                                rows={2}
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                style={{ height: "auto" }}
                                name="address"
                                id="address"
                                value={state.address}
                              ></textarea>
                            </p>

                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="city">
                                City&nbsp;<span className="required">*</span>
                              </label>
                              <input
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="city"
                                id="city"
                                value={state.city}
                              />
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="country_id">
                                {" "}
                                Country&nbsp;<span className="required">*</span>
                              </label>
                              <select
                                name="country_id"
                                value={state.country_id}
                                onChange={(e) => handleInputChange(e)}
                                className="orderby filterSelect"
                              >
                                <option value="">Select Country</option>
                                {country.map((country, index) => {
                                  return (
                                    <option value={country.id}>
                                      {" "}
                                      {country.country}{" "}
                                    </option>
                                  );
                                })}
                              </select>
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="state_id">
                                {" "}
                                State&nbsp;<span className="required">*</span>
                              </label>
                              <select
                                name="state_id"
                                value={state.state_id}
                                onChange={(e) => handleInputChange(e)}
                                className="orderby filterSelect"
                              >
                                <option value="">Select State</option>
                                {states.map((state, index) => {
                                  return (
                                    <option value={state.id}>
                                      {" "}
                                      {state.state}{" "}
                                    </option>
                                  );
                                })}
                              </select>
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="pincode">
                                Postcode&nbsp;
                                <span className="required">*</span>
                              </label>
                              <input
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="pincode"
                                id="pincode"
                                value={state.pincode}
                              />
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="mobile">
                                Primary Contact&nbsp;
                                <span className="required">*</span>
                              </label>
                              <input
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="mobile"
                                id="mobile"
                                value={state.mobile}
                              />
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="phone">Secondary Contact </label>
                              <input
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="phone"
                                id="phone"
                                value={state.phone}
                              />
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="tax_id"> Tax ID </label>
                              <input
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="tax_id"
                                id="tax_id"
                                value={state.tax_id}
                              />
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="tax_expiry_date">
                                {" "}
                                Tax Valid Till{" "}
                              </label>
                              <input
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                type="date"
                                min={date}
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="tax_expiry_date"
                                id="tax_expiry_date"
                                value={state.tax_expiry_date}
                              />
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="tax_certificate">
                                {" "}
                                Tax Certificate{" "}
                              </label>
                              <input
                                onChange={(e) => handleInputChange(e)}
                                type="file"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="tax_certificate"
                                id="tax_certificate"
                              />
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="tob_license_no">
                                {" "}
                                Tobacco ID&nbsp;
                                <span className="required">*</span>
                              </label>
                              <input
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="tob_license_no"
                                id="tob_license_no"
                                value={state.tob_license_no}
                              />
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="tob_expiry_date">
                                {" "}
                                Tobacco Valid Till{" "}
                              </label>
                              <input
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                type="date"
                                min={date}
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="tob_expiry_date"
                                id="tob_expiry_date"
                                value={state.tob_expiry_date}
                              />
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="tob_certificate">
                                {" "}
                                Tobacco Certificate{" "}
                              </label>
                              <input
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                type="file"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="tob_certificate"
                                id="tob_certificate"
                              />
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="dba"> Doing Business As </label>
                              <input
                                onKeyDown={(e) => _handleKeyDown(e)}
                                onChange={(e) => handleInputChange(e)}
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="dba"
                                id="dba"
                                value={state.dba}
                              />
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="cc_tax_id">
                                {" "}
                                Customer Membership&nbsp;
                                <span className="required">*</span>{" "}
                              </label>
                              <select
                                name="cc_tax_id"
                                value={state.cc_tax_id}
                                onChange={(e) => handleInputChange(e)}
                                className="orderby filterSelect"
                              >
                                <option value="">Select Membership</option>
                                {customerCategory.map((cc, index) => {
                                  return (
                                    <option value={cc.cc_id}>
                                      {" "}
                                      {cc.cc_name}{" "}
                                    </option>
                                  );
                                })}
                              </select>
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <input
                                value=""
                                type="hidden"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="extra"
                                id="extra"
                              />
                            </p>

                            <div className="woocommerce-privacy-policy-text">
                              <p>
                                Your personal data will be used to support your
                                experience throughout this website, to manage
                                access to your account, and htmlFor other
                                purposes described in our{" "}
                                <a
                                  href="#/privacy-policy/"
                                  className="woocommerce-privacy-policy-link"
                                  target="_blank"
                                >
                                  privacy policy
                                </a>
                                .
                              </p>
                            </div>
                            <p className="woocommerce-form-row form-row">
                              <button
                                type="button"
                                onClick={() => handleSubmit()}
                                className="woocommerce-Button woocommerce-button button woocommerce-form-register__submit"
                                name="register"
                                value="Register"
                              >
                                Register
                              </button>
                            </p>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Popup
                    title={"Customer Registered Successfully"}
                    content={`Thank you for your registration with ALL1 wholesale,You will be able to login into your account after we send you confirmation on your email`}
                    show={confirmationPopup}
                    isOk={true}
                    setShow={setconfirmationPopup}
                    confirmClicked={(e) => {
                      navigate("/login");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Register;
