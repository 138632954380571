import axios from "axios";
import { handleAuthFailure } from "../utils/constant";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

let token = await localStorage.getItem("token");
let headers = {
  Authorization: `Bearer ${token}`,
};

const retriveCategories = async (limit = "all") => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/categories?limit=${limit}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveSingleCategory = async (subcat) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/single-category?category=${subcat}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveCategoryByBrand = async (subcat) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/category-by-brand?brand_id=${subcat}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveBrands = async (cat) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/brands?category=${cat}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

export {
  retriveCategories,
  retriveCategoryByBrand,
  retriveSingleCategory,
  retriveBrands,
};
