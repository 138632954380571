import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { getLogos } from "../../services/CartService";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cleanNavigationString } from "../../utils/constant";

export default function LogoScroll() {
  const [data, setdata] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  var settings = {
    // dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: data?.length > 7 ? 8 : data?.length,
    slidesToScroll: data?.length > 7 ? 2 : 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getData = () => {
    getLogos()
      .then((response) => {
        setdata(response?.data?.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const handleRedirection = (brandId, catId, brandName) => {
    const queryString = new URLSearchParams();
    queryString.append("brandIds", brandId);
    queryString.append("categoryId", catId);
    queryString.append("name", brandName);

    navigate(`/product-brand/${brandId}?${queryString.toString()}`);
  };
  return (
    <div
    // className="p-5"
    >
      <Slider {...settings}>
        {data?.map((x) => (
          <div>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => handleRedirection(x?.brand?.id, x?.category?.slug,x?.brand?.brand_name)}
            >
              <img
                className="p-2 bg-black"
                src={x?.image}
                alt="Logo"
                style={{ width: "130px", height: "75px", margin: "10px" }}
              />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
}
